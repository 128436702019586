<template>
  <div class="page">
    <div class="toproad">
      <div class="topimg"><img :src="theData.thisAvatarUrl" alt="" /></div>

      <template v-if="ifindex == 1">
        <div class="roadtitle" :style="{ color: theData.color }">
          {{ theData.thisGradeName }}
        </div>
        <div class="thecode">{{$t('MY_ORD_71')}} {{ theData.globalCode }}</div>
        <div class="thecode">{{$t('S_L_4')}} {{ theData.countryCode }}</div>
        <!-- <div  class="thecode">全球编号 {{ theData.memberCode }}</div>
        <div class="thepv">累计业绩：{{ theData.achievement }}</div>
        <div class="themember">
          尊敬的会员 {{ theData.memberName }} 欢迎您！
        </div> -->
      </template>
      <template v-if="ifindex == 2">
        <div class="roadtitle">{{ theData.thisAwardsName }}</div>
        <!-- <div class="thepv">累计业绩：{{ theData.achievement }}</div>
        <div></div> -->
      </template>
    </div>
      <div class="awards-content" v-if="ifindex==2">
        <div class="awards-list" v-for="(item,index) in theData.awardsList">
            <div class="awards-img" v-if="item.image">
               <img :src="item.image" >
           </div>
            <div class="award-name">{{ item.awardsName }}</div>
<!--            {{item.awardsValue*index}}-->
            <div class="height" :style="{height:item.awardsValue*index+200+'px',background:item.color}">
               <div class="price">{{item.communityCheckStr}}PV</div>

                <div class="bottom" :style="{fontSize:item.size}" v-if="item.textContent && index>=5 && item.awardsValue>=15" >{{ item.textContent }}</div>
                <div class="bottom bottom-s" :style="{fontSize:item.size}" v-if="item.textContent && index<5 && item.awardsValue>=15" >{{ item.textContent }}</div>
            </div>
        </div>
      </div>
    <div class="echart" id="mychart" v-if="ifindex==1" :style="myChartStyle"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import * as gro from "@/api/wallet.js";
export default {
  name: "RoadtoGrowth",
  data() {
    return {
      ifindex: "", //1等级2奖衔
      thexAxis: [],
      iconList: [],
      myChart: {},
      myChartStyle: {
        float: "center",
        width: "1800px",
        height: "600px",
        paddingTop: "20px",
        // margin: "20px",
      }, //图表样式
      indexList: [], //高度
      theData: {},
      textList:[],
    };
  },
  created() {},
  mounted() {
    // this.initEcharts();
    this.ifindex = this.$route.query.index;
    this.getList();
  },
  methods: {
    getList() {
      if (this.$route.query.index == 1) {
          gro.getGrowUpGrade().then((res) => {
              if (res.code == 200) {
                  this.theData = res.data;
                  res.data.gradeList.forEach((ele) => {
                      this.thexAxis.push(ele.gradeName);
                      ele.value = ele.gradeValue;
                      ele.symbol = "image://" + ele.image;
                      ele.symbolSize = [90, 80];

                      this.indexList.push(ele.gradeValue);
                  });
                  this.iconList = res.data.gradeList;
                  this.initEcharts();
              }
          });
      } else if (this.$route.query.index == 2) {
        gro.getGrowUpAwards().then((res) => {
          if (res.code == 200) {
            this.theData = res.data;
            res.data.awardsList.forEach((ele,index) => {
              if(index==3 || index==4){
                ele.size = '28px'
              }
              if(index>=5){
                  let size = 24
                  ele.size = size+(index*2)+'px'
              }
              this.thexAxis.push(ele.awardsName);
              ele.value = ele.awardsValue + 10;
              ele.symbol = "image://" + ele.image;
              ele.symbolSize = [110, 80];

              this.indexList.push(ele.awardsValue);
            });
            this.iconList = res.data.awardsList;
          }
        });
      }
    },
    initEcharts() {
      const globalIndex = this.$route.query.index;
      const option = {
        grid: {
          // bottom:140,
          // // 距离左方轴数值
          // x: 248,
          // // y:200,
          // x2: 264,
          // // 距离下方轴数值
          // // y2: 120,
          // containLabel: true,
        },

        xAxis: {
          data: this.thexAxis,
          show: false,
          axisLine: {
            lineStyle: {
              color: "#999",
              width: 5,
              fontSize: "18px",
            },
          },
          axisLabel: {
            padding: [14, 0, 0, 0],
            fontSize: 14,
            color: "#000",
          },
        },
        yAxis: {
          show: false,
          max: 80,
        },
        series: [
          {
            type: "bar",
            barWidth: "100",
            data: this.iconList,
            label: {
              show: true,
              position: "top",
              fontSize: 16,
              color: "#000",
              formatter: function (params) {
                // console.log('🌈',params)
                // var inner =
                //  params.data.thename)
                return params.name;
              },
            },
            itemStyle: {
              normal: {
                borderRadius: 8,

                color: function (param) {
                  const color = param.data.color;
                  return color;
                },
              },
              paddingTop: 20,
              borderRadius: 8,
              // color: {
              //   type: "linear",
              //   x: 0,
              //   y: 0,
              //   x2: 0,
              //   y2: 1,
              //   colorStops: [
              //     {
              //       offset: 0,
              //       color: "red", // 0% 处的颜色
              //     },
              //     {
              //       offset: 1,
              //       color: "yellow", // 100% 处的颜色
              //     },
              //   ],
              //   global: false, // 缺省为 false
              // },
            },
          },
          {
            type: "pictorialBar",
            barGap: "100%",
            symbolPosition: "end",
            // symbolSize: 20,
            symbolOffset: [0, "-150%"],
            data: this.iconList,

            label: {
              show: true,
              position:"insideTop",
              offset:[0,130],
              fontSize: 16,
              color: "#ffffff",
              align: "center",
              formatter: function (params) {
                if (globalIndex == 2) {
                  var inner =params.data.communityCheckStr ;
                }
                if (globalIndex == 1) {
                  var inner = Number(params.data.startValue);
                }
                return inner;
               
              },
            },
          },
        ],
      };
      const myChart = echarts.init(document.getElementById("mychart"));
      myChart.setOption(option);
      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.awards-list:nth-child(1){
  margin-top: 110px !important;
}
.bottom-s{
  color: #fff;
  width: 50px !important;
  font-size: 24px !important;
  bottom: 0;
}
.bottom{
  top: 50%;
  text-align: center;
  position: absolute;
  width: 44px;
  justify-content: center;
  color: #fff;
  align-items: center;
  display: flex;
  margin-top: -50px;
}
.award-name{
  text-align: center;
}
.height{
  border-radius: 8px;
  width: 110px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.price{
  position: absolute;
  top: 20px;
  color: #fff;
  font-size: 14px;
}
.awards-img{
  img{
    width: 124px;
    height: 109px;
  }
}
.awards-list{
  margin-left:30px;
  position: relative;
  width: 110px;
  text-align: center;
}
.awards-content{
  display: flex;
  justify-content: center;
  margin: 30px 0;
  position: relative;
  align-items: end;

}
.page {
  padding: 20px;
  .toproad {
    text-align: center;
    background: #fff;
    padding: 50px 0;
    .topimg {
      img {
        width: 190px;
        height: 180px;
      }
    }
    .roadtitle {
      font-size: 32px;
      font-weight: 500;
      margin-top: 10px;
      //   line-height: 34px;
    }
    .thecode {
      font-size: 18px;
      line-height: 40px;
      color: #666666;
    }
    .thepv {
      font-size: 14px;
      margin-top: 10px;
      //   line-height: 16px;
    }
    .themember {
      margin-top: 20px;
      font-size: 18px;
    }
  }
}
</style>